import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <section
      style={{
        height: "100vh",
        width: "100vw",
        verticalAlign: "middle",
        display: "table-cell",
      }}
    >
      <h1>404</h1>
      <h3>Kunde inte hittas.</h3>
      <Link to="/" className="cta back">
        Startsidan
      </Link>
    </section>
  </Layout>
)

export default NotFoundPage
